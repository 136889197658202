/* >> Mixins
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
@mixin selectColor{
  ::selection {
  background: $color-selection; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: $color-selection; /* Gecko Browsers */
  }
}

@mixin transform($transform-type,$transform-amount){
  -webkit-transform: $transform-type+unquote('(#{$transform-amount})');
  -moz-transform: $transform-type+unquote('(#{$transform-amount})');
  -ms-transform: $transform-type+unquote('(#{$transform-amount})');
  -o-transform: $transform-type+unquote('(#{$transform-amount})');
  transform: $transform-type+unquote('(#{$transform-amount})');
  // rotate - ex: transform: rotate(30deg);
}

@mixin transition($property: all,$timing: linear,$delay:0.4s) {
  -webkit-transition: $property $timing $delay;
  -moz-transition: $property $timing $delay;
  -ms-transition: $property $timing $delay;
  -o-transition: $property $timing $delay;
  transition: $property $timing $delay;
}

@mixin appearencNone{
  appearance:none;
  -moz-appearance:none;
  -webkit-appearance:none;
  border-radius: 0;
}

@mixin allappearencNone{
  appearance:none;
   -moz-appearance:textfield;
  -webkit-appearance:none;
  border-radius: 0;
}

@mixin selectDisable{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select:none;
  -o-user-select:none; 
  user-select:none;
}