/* >> Popup
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.popup-wrapper{ background: rgba(200, 247, 217, 0.4); @extend %flexBox; align-items: center; justify-content: center; position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 10;
  .popup-inner{ background: $color-white url(../images/card-background.png) no-repeat left bottom; border: 1px solid $color-green; max-width: 990px; width: 90%; border-radius: 20px;
    .popup-header{
      padding: 1em; font-size: 20px; border-radius: 18px 18px 0 0; background: rgba(0, 160, 59, 0.8); color: $color-white;
      h4{width: calc(100% - 30px); display: inline-block;}
      .exit-btn{display: inline-block; width: 20px; height: 20px; background: url(../images/icon-x-white.svg) no-repeat center center; background-size: 20px;}
    }
    .popup-content{ padding: 1em;
      span{ display: block; font-size: 16px; }
      .btn{ @include transition; display: inline-block; padding: 15px 28px; margin: 1em 1em auto 0; background: $color-green; color: $color-white; border: 1px solid $color-green; border-radius: 10px;
        &:hover{ @include transition; color: $color-green; background-color: #ffffff; }
      }
    }
  }
  &.alert{ background: rgba(247, 209, 209, 0.4);
    .popup-inner{ border: 1px solid $color-white; background: $color-white;
      .popup-header{background: $color-red;}
      .popup-content{
        span{ color: $color-red; }
        .btn{ background: $color-red; border: 1px solid $color-red;
          &:hover{ background: $color-white; color: $color-red; }
        }
      }
    }
  }
}

.ui-widget-overlay{
  background: rgba(215, 215, 215, 0.6); position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 100;
}
.ui-dialog{
  z-index: 101; background: $color-white; border: 1px solid $color-green !important; border-radius: 20px; max-width: 990px;
  .ui-dialog-titlebar{
    padding: 1em; font-size: 20px; border-radius: 18px 18px 0 0; background: rgba(0, 160, 59, 0.8); color: $color-white; display: flex; align-items: center;
    span{width: calc(100% - 30px); display: inline-block; font-weight: 600;}
    .ui-dialog-titlebar-close{ border: 0; font-size: 0; display: inline-block; width: 20px; height: 20px; background: url(../images/icon-x-white.svg) no-repeat center center; background-size: 20px; }
  }
  .ui-dialog-content{
    padding: 1em;
    span{ display: block; font-size: 16px; }
  }
  .ui-dialog-buttonpane{
    padding: 0 1em 1em;
    .ui-button{
      @include transition; display: inline-block; padding: 15px 28px; margin: 1em 1em auto 0; background: $color-green; color: $color-white; border: 1px solid $color-green; border-radius: 10px;
      &:hover{ @include transition; color: $color-green; background-color: #ffffff; }
    }
  }
  &.alert{
    border: 1px solid $color-white !important; background: $color-white;
    .ui-dialog-titlebar{background: $color-red;}
    .ui-dialog-content{
      span{ color: $color-red; }
    }
    .ui-dialog-buttonpane{
      .ui-button {
        background: $color-red; border: 1px solid $color-red;
        &:hover{ background: $color-white; color: $color-red; }
      }
    }
  }
}
