/* >> Dashboard
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.dashboard-container{ @extend %flexBox; flex-direction: row;
  .dashboard{ @extend %flexBox; flex-direction: row;
    .side-bar{width: 0; overflow-x: hidden; transition: 0.3s;position: fixed; z-index: 2;
      background: $color-green url(../images/card-background.png) no-repeat left bottom; background-size: 100%; height: 100vh; top: 0px;
      ul{
        li{
          a{@include transition; background: $color-green; display: block; text-align: center; font-size: 18px; padding: 1em 0; color: $color-white;
            &.selected{background: $color-teal; font-size: 20px;}
            &:hover{@include transition; background: $color-teal;}
          }
        }
      }
      @media #{$mq-pc_1200}{display: block;position: relative; height: auto; width: 280px!important;}
    }
    .dashboard-content{width: 100%; padding: 2em;
      @media #{$mq-pc_1200}{
        width: calc( 100% - 280px ); padding: 2em 0 2em 2em;
      }
    }
    .openbtn, .closebtn{ display: block; width: 36px; height: 36px;
      @media #{$mq-pc_1200}{
        display: none;
      }
    }
    .openbtn{ background: $color-white url(../images/icon-menu.svg) no-repeat center center; position: fixed; top:0; margin: 5px; z-index: 1; -webkit-box-shadow: 5px 5px 15px 5px rgba(18, 18, 18, 0.4); box-shadow: 5px 5px 15px 5px rgba(18, 18, 18, 0.4); border-radius: 50px;}
    .closebtn{ background: url(../images/icon-close-white.svg) no-repeat center center; position: absolute; top: 5px; left: 5px; z-index: 3;}
  }
}

.white-link {
  &:link, &:visited {
    color: white;
  }
  &:hover {
    color: rgb(0,160,59);
  }
}

/* >> Dashboard Cards
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.card{
  width: 100%;
  background: rgb(0,160,59);
  background: -moz-linear-gradient(90deg, rgba(0,160,59,1) 0%, rgba(175,203,55,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,160,59,1) 0%, rgba(175,203,55,1) 100%);
  background: linear-gradient(90deg, rgba(0,160,59,1) 0%, rgba(175,203,55,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a03b",endColorstr="#afcb37",GradientType=1);
  border-radius: 20px;
  margin-bottom: 2em;
  &:last-child{margin-bottom: 0;}
  .card-background{background: url(../images/card-background.png) no-repeat left bottom; padding: 1em; min-height: 280px;}
  .card-header{padding: 0.5em; display: flex; justify-content: space-between; align-items: center;
    h4{ font-size:20px; color:$color-white; }
    a{display: block; height: 25px; width:30px; background: url(../images/icon-more.svg) no-repeat center center;}
  }
  .card-content{ padding:1em; margin: 2em auto; border-radius: 20px; background: rgba(255,255,255,0.2);
    span{ display: block; color: $color-white; font-size: 20px; margin-bottom: 25px; font-weight: 100;
      i{white-space: nowrap;}
      &:last-child{margin-bottom: 0;}
    }
  }
  .card-date{font-size:16px; color:$color-white;font-weight: 100;}
}
