/* >> Settings
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.card-background{
  .card-content-settings{
    .btn-wrapper .button {
      margin: 32px auto 32px 0;
    }
  }
  .stat-text{ width: 100%; max-width: 100%; margin-bottom: 20px;
    p{color: $color-white;}
  }
}

.email-list-table{
  background: rgba($color-white, .8); border-radius: 20px; border: 1px solid $color-green; text-align: center;
  .email-list-table-mobile-title{ display: block; text-align:left; padding: 1em; border-bottom: 1px solid $color-green; background: $color-green; color: $color-white; border-radius: 16px 16px 0 0;
    @media #{$mq-pc_880}{ display: none; }
  }
  .email-list-table-header{ display: none; 
    .email-list-table-header-item{ @extend %flexBox; justify-content: center; align-items: center; font-weight: bold;
      &.email{ width: 50%; }
      &.status{ width: 25%; }
      &.delete{ width: 25%; }
      .order-button-wrapper{ display: inline-block; padding-left: 5px;
        .order-up,.order-down{ display: block; width: 10px; height: 10px;}
        .order-up{ background: url(../images/icon-up.svg) no-repeat center center; background-size: 10px;}
        .order-down{ background: url(../images/icon-down.svg) no-repeat center center; background-size: 10px;  }
      }
      
    }
    @media #{$mq-pc_880}{
      display: -ms-flexbox; display: flex; -webkit-box-flex: 0; -ms-flex: 0 1 auto; flex: 0 1 auto; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -ms-flex-wrap: wrap; flex-wrap: wrap; width: 100%;
      align-items: center; padding: 1em; border-bottom: 1px solid $color-green; 
    }
  }
  .email-list-table-body{@extend %flexBox; padding:0; font-size: 16px;
    .email-list-table-row{ @extend %flexBox; min-height: 40px; align-items: center; padding: 1em;
      @media #{$mq-pc_880}{ width: 100%;}
      &:nth-child(2n){background: #3c7e792b;}
      .cell{ width: 100%; color: $color-green; @extend %flexBox; margin-bottom: 10px; text-align: left;
        @media #{$mq-pc_880}{ margin-bottom: 0; text-align: center; justify-content: center;
          &.cell-data-email{ width: 50%; }
          &.cell-data-status{ width: 25%; }
          &.cell-data-delete{ width: 25%; }
        }
        .cell-data-mobiletitle{ display: inline-block; font-weight: bold; width: 100%;
          @media #{$mq-slide-spec-md}{ width: 50%;}
          @media #{$mq-pc_880}{ display: none; }
        }
        .btn_remail{@include transition; display: block; padding: 10px 20px; margin: 0px auto 0 0; background: $color-green; color: $color-white; border: 1px solid $color-green; border-radius: 10px;
          &:hover{ @include transition; color: $color-green; background-color: #ffffff; }
          @media #{$mq-pc_880}{ margin: 0px auto; }
        }
        .btn_delete{@include transition; margin: 0 auto 0 0; display: block; width: 28px; height: 28px; border: 2px solid #fb344954; border-radius: 3px; background: $color-red url(../images/icon-x-white.svg) no-repeat center center; background-size: contain;
          &:hover{ @include transition; background: $color-white url(../images/icon-x.svg) no-repeat center center;}
          @media #{$mq-pc_880}{ margin: 0px auto; }
        }
      }
    }
    .base-forms{width:100%;
      .form-item{width:100%; 
        @media #{$mq-pc_880}{max-width: 350px;}
      }
      .btn-save-form{@include transition; display: block; padding: 10px 20px; margin: 0px auto 0 0; background: $color-green; color: $color-white; border: 1px solid $color-green; border-radius: 10px;
      &:hover{ @include transition; color: $color-green; background-color: #ffffff; }
      @media #{$mq-pc_880}{ margin: 0px auto; }
      }
    }
  }
}
