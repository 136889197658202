/* >> Header
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
header{ border-bottom: 1px solid $color-green; }
.top-section{
  background: $color-green; padding: 7px 0; text-align: right;
  .top-section-item{
    @include transition; display: inline-block; margin-left: 16px; font-size: 16px; line-height: 1.8; color: $color-white;
    &::before{ content:""; display: inline-block; height: 20px; width: 20px; margin: 0px 10px -5px 0; background: url(../images/icon-web.svg) no-repeat center center; background-size: 20px; }
    &:first-child{margin-left: 0px;}
    &:hover{
      @include transition; color: $color-lime;
      &::before{background: url(../images/icon-web-lime.svg) no-repeat center center;}
    }
  }
}
.logo { display: block; margin: 0 auto; padding: 25px 0; width: 200px; max-width: 90%; 
  @media #{$mq-iPad_port} {
    margin: 0 auto 0 0;
  }
  img{ display: block; width: 100%; }
}
.seo-wrapper{ display: none; }
.logo-link-container{ @extend %flexBox; align-items: center; flex-direction: column; -ms-flex-direction: column;
  @media #{$mq-iPad_port} {
    margin: 0 auto 0 0;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.login-reg-wrapper{
  .header-link{
    @include transition;
    font-size: 16px;
    color: $color-green;
    padding-left: 40px;
    margin-right: 25px;
    &:last-child{margin-right: 0;}
    &.header-link-reg{background: url(../images/icon-reg.svg) no-repeat center left;}
    &.header-link-login{background: url(../images/icon-login.svg) no-repeat center left;}
    &.header-link-logout{background: url(../images/icon-logout.svg) no-repeat center left;}
    &.header-link-profile{background: url(../images/icon-profile.svg) no-repeat center left;}
    @media #{$mq-slide-spec-sm} {font-size: 20px;}
    &:hover{
      @include transition;
      color: $color-lime;
      &.header-link-reg{background: url(../images/icon-reg-lime.svg) no-repeat center left;}
      &.header-link-login{background: url(../images/icon-login-lime.svg) no-repeat center left;}
      &.header-link-logout{background: url(../images/icon-logout-lime.svg) no-repeat center left;}
      &.header-link-profile{background: url(../images/icon-profile-lime.svg) no-repeat center left;}
    }
  }
}
