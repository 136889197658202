/* --- --- --- --- --- --- --- --- --- --- --- --- --- ---*\
Scss - Fonts - Teva Sans - only light weight becouse another woff's are wrong
\* --- --- --- --- --- --- --- --- --- --- --- --- --- --- */
@font-face {
  font-family: 'Teva Sans Web';
  font-style: 'normal';
  font-weight: '100';
  font-display: swap;
  src: url('../fonts/teva-sans-light.otf') format('otf'),
       url('../fonts/teva-sans-light.eot?#iefix') format('embedded-opentype'),     
       url('../fonts/teva-sans-light.woff2') format('woff2'),
       url('../fonts/teva-sans-light.woff') format('woff');
}