/* >> Main Layout
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
html{height: 100%; margin: 0; padding: 0; }
body{ @include selectColor; @extend %stickyFooter; background: $color-white; margin: 0; padding: 0; color: $color-baseText; font-family: $baseFont , sans-serif; line-height: 1.5; font-weight:500;
	.page-wrapper{ width: 100%; margin: 0 auto; padding-bottom:1px;
		&.center-container{ display: flex; align-items: center; justify-content: center; }
	}
	.container{
		max-width: 1440px; width: 90%; margin: 0 auto;
		&.logo-link-container{ flex-wrap: initial; }
		.page-title{ position:relative; font-size: 30px; color: $color-green; padding:0 0 30px 50px;
			&:before{ content: " ";  position: absolute; top: 17px; left: 0; width:40px; height: 2px; background-color: $color-green;
				@media #{$mq-slide-spec-md}{ top: 30px; width:60px;}
			}
			@media #{$mq-slide-spec-md}{font-size: 50px; padding:0 0 30px 80px;} 
		}
		.inner-page-title{position:relative; font-size: 30px; color: $color-green; padding:0 0 30px 40px;
			&:before{ content: " ";  position: absolute; top: 17px; left: 0; width:30px; height: 2px; background-color: $color-green;}
		}
		&.main-container{padding: 50px 0;position: relative;
			&.main-container-registration{max-width:880px; text-align: center}
		}
		.button{@include transition; display: block; padding: 15px 28px; margin: 32px auto; background: $color-green; color: $color-white; border: 1px solid $color-green; border-radius: 10px;
			&:hover{ @include transition; color: $color-green; background-color: #ffffff; }
		}
	}
}

/* >> Landing
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
.content-wrapper{ @extend %flexBox;
	.image-wrapper{width:50%; margin: 0 auto; padding: 0; margin-bottom: 15px;
		@media #{$mq-slide-spec-sm} {margin-bottom: 30px;}
		@media #{$mq-iPad_land} {width:49%; margin-right: 2%; margin-bottom:0; }
		.image-frame{ width: 100%; text-align: center; position: relative; padding-bottom: 100%; border-radius: 50%; overflow: hidden;
			img{
				position: absolute;	width: 100%; height: 100%; top: 0; left: 0; object-fit: cover;
			}
		}
		&:after{ content:" "; display: block; width: 80px; height: 80px; background: url(../images/icon-moment-circle.svg) no-repeat center center; margin: -20% 0 0 auto;
			@media #{$mq-slide-spec-md}{  width: 150px; height: 150px; }
			@media #{$mq-iPad_land}{ width: 340px; height:340px; margin: -40% 0 0 auto;}
		}
	}
	.text-wrapper{ @extend %statText; width:100%;
		@media #{$mq-iPad_land} { width:49%; }
	}
}

/* >> Basic - Others
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
.stat-text{ @extend %statText; max-width: 880px; width: 90%; margin: 0 auto;
	.stat-text-title{ font-size: 40px; color: $color-green; padding:0 0 30px 0;}
	&.stat-text-center{ text-align: center;
		.btn{margin: 0 auto; display: inline-block;}
	}
	.stat-text-main-img{ float: left; margin: 0 0 1em 0;
		@media #{$mq-tab_720} { max-width:37%; margin: 0 1em 0em 0em;}
	}
}
body
.container {
	.btn-wrapper{
		.button{ display: block; max-width: 350px; margin: 32px auto 32px 0;
		}
		@media #{$mq-pc_880} { display: -ms-flexbox; display: flex; -webkit-box-flex: 0; -ms-flex: 0 1 auto; flex: 0 1 auto; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -ms-flex-wrap: wrap; flex-wrap: wrap; width: 100%;
			.button{ max-width: initial; margin: 32px auto; display: inline-block;
				&:last-child{margin: 32px 0 32px auto;}
				&:first-child{margin: 32px auto 32px 0;}
			}
		}
	}
}