/* >> E-Bill
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.filter-wrapper{@extend %baseForm; @extend %flexBox; -ms-flex-direction: column; flex-direction: column; padding: 0 0 20px 0; /*margin-bottom: 20px;*/
  .filter-item{ display: inline-block; margin-bottom: 20px;
    input{ width: 100%;
     
    }
    .filter-item-label{ display:block; color: $color-white; margin-bottom: 7px; font-weight: bold;}
    .filter-item-date-picker{ display: inline-block;  width: 100%;  margin-bottom: 10px;
      @media #{$mq-slide-spec-md}{
        width: 49%; margin-right: 1%; margin-bottom: 0;
        &:last-child{margin-right: 0;}
      }
    }
    &.download{width: 100%; 
      &:nth-child(2n){width: 100%;margin-top: 20px;
        @media #{$mq-iPad_land}{
          width: 40%;margin-top: 0;
        }
      }
      @media #{$mq-iPad_land}{
        width: 60%;
      }
      input{ width: 100%; margin-bottom: 10px;
        @media #{$mq-slide-spec-md}{
          max-width: 200px; width: initial; margin-bottom: 0px;
        }
      }
    }
    @media #{$mq-iPad_land}{
      margin: 0 auto;
      &:first-child{margin: 0 auto 0 0;}
    }
  }
  .button{
    &.filter-item-btn{display: block; margin: 0 0 0 auto; max-width: 190px; text-align: center;
      &.download-btn.is-disable {
        opacity: 0.4; cursor: no-drop;
        &:hover{background: $color-green; color: $color-white}
      }
      @media #{$mq-slide-spec-sm}{
        display: inline-block;
      }
    }

  }
  &:after{content: '';}
  &:last-child{margin-bottom: 0;}
  @media #{$mq-iPad_land}{
    justify-content: space-between; align-items: end; -ms-flex-direction: row; flex-direction: row;
  }
}

/* >> Date picker
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
#ui-datepicker-div {
  background: #edfdf3;
  width: 200px;
  text-align: center;
  margin-top: -2px;
  border-radius: 10px;
  border: 2px solid #00a03b54;
  color: $color-green;
  font-size:11px;
  padding:10px;
  display: none;

  &.ui-datepicker{
    a {background: $color-green; color: $color-white; cursor: pointer; text-decoration: none; display: inline-block; z-index: 1; border: 1px solid green; border-radius: 5px; padding: 5px;
      &.ui-datepicker-prev { float:left; }
      &.ui-datepicker-next { float:right;}
    }
    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default{ font-weight: normal; }
    .ui-widget-header { font-weight: bold; }
  }
  select { padding: 6px 0; border: 1px solid $color-green; border-radius: 5px; color: $color-green; margin: 0 1px; width: 46px; font-size: 9px; }
  .ui-datepicker-buttonpane{padding: 5px 0 0 0;
    button{ 
      display: inline-block;
      margin: 0 2px;
      padding: 5px 10px;
      background: $color-green;
      color: #ffffff;
      border: 1px solid $color-green;
      border-radius: 5px;}
  }
  .ui-datepicker-title{
    span {
      padding-top: 6px;
      font-size: 12px;
      display: inline-block;
    }
  }
  table{
    &.ui-datepicker-calendar {
      margin: 0!important;
      width: 100%;
      th {
        padding: .7em .3em;
        text-align: center;
        font-weight: bold;
        border: 0;
        color: $color-green;
      }
      tbody{
        tr{
          td{
            a{background: transparent; color: $color-green; border: 0px solid $color-green;
              &.ui-state-default.ui-state-active{background: $color-green; color: $color-white;}
            }
          }
        }
      }
    }
  td { text-align:center; }
  .ui-datepicker-title {text-align: center; font-weight:bold;}
  }
}


  
