/* >> Footer
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
footer{
  background: $color-light-grey;
  color: $color-grey;
  font-size: 12px;
  .container{ @extend %flexBox; align-items: center; flex-direction: column; -ms-flex-direction: column; -ms-flex-wrap: initial; flex-wrap: initial; min-height: 50px;
    @media #{$mq-iPad_land} {
      -ms-flex-direction: row;
      flex-direction: row;
      justify-content: space-between;
    }
    .footer-item{margin: 10px auto;
      .footer-item-link{ @include transition; color: $color-grey; margin-left: 10px; display: inline-flex;
        &.footer-item-link-phone{background: url(../images/icon-phone.svg) no-repeat left center; background-size: 20px; padding-left: 25px;}
        &:first-child{margin-left: 0;}
        &:hover{
          @include transition; color: $color-lime;
          &.footer-item-link-phone{background: url(../images/icon-phone-lime.svg) no-repeat left center; background-size: 20px;}
        }
      }
      img{width: 180px;}
      @media #{$mq-iPad_land} {margin: 0 0 0 0;}
    }
  }
  @media #{$mq-slide-spec-sm} {font-size: 16px;}
}