/* >> Groups
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
%stickyFooter {
    display: flex;
    flex-direction: column;
    .sticky-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column wrap;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        .page-wrapper {
            width: 100%;
            flex: 1 1 auto;
        }
    }
}

%flexBox {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

%clearFix {
    &:after {
        clear: both;
        content: " ";
        display: block;
        font-size: 0;
        line-height: 0;
        visibility: hidden;
        width: 0;
        height: 0;
    }
}

%statText {
    line-height: 1.8;
    p {
        font-size: 16px;
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
        @media #{$mq-slide-spec-sm} {
            font-size: 18px;
            margin-bottom: 1em;
        }
    }
    a {
        text-decoration: none;
        color: $color-teal;
        font-weight: bold;
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $color-baseText;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }
        &:hover {
            @include transition;
            color: $color-baseText;
            &:after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }
    h4 {
        font-size: 24px;
    }
    h5 {
        font-size: 22px;
    }
    h6 {
        font-size: 20px;
    }
    i {
        font-style: italic;
    }
    b,
    strong {
        font-weight: bold;
    }
    ul,
    ol {
        margin-left: 2em;
        margin-bottom: 1em;
    }
    ul {
        list-style-type: disc;
        list-style-image: url("../images/icon-list-img.svg");
        text-align: left;
    }
    ol {
        list-style-type: decimal;
    }
    ol li,
    ul li {
        margin-bottom: 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }
    table {
        display: none;
        @media #{$mq-tab_720} {
            display: table;
        }
    }
}

%baseForm {
    @extend %clearFix;
    .form-item {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            font-weight: normal;
        }
    }
    .form-item-title {
        margin-bottom: 4px;
        color: $color-green;
    }
    .form-item-note {
        font-size: 14px;
        margin-top: 6px;
        margin-bottom: 6px;
        color: $color-grey;
    }
    .form-item-alert {
        font-size: 14px;
        color: $color-red;
        margin-top: 6px;
        margin-bottom: 6px;
    }
    input,
    select,
    textarea {
        @include appearencNone;
        border-radius: 10px;
        width: 100%;
        padding: 0 8px;
        border: 2px solid #00a03b54;
        outline-color: $color-green;
        color: $color-baseText;
        font-family: $baseFont;
        font-size: 16px;
    }
    input,
    select {
        height: 46px;
    }
    select {
        padding-right: 22px;
        background: $color-white;
        position: relative;
    }
    .filter-item-select-background {
        content: " ";
        background: url(../images/icon-down.svg) no-repeat right center;
        background-size: 10px;
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 17px;
        right: 9px;
    }
    textarea {
        min-height: 170px;
        resize: vertical;
    }
    input::-ms-clear {
        display: none;
    }
    .btn-submit-form {
        @include transition;
        display: block;
        width: 100%;
        margin: 32px auto;
        color: white;
        background: $color-green;
        cursor: pointer;
        font-family: $baseFont;
        font-size: 25px;
        border: 1px solid $color-green;
        height: auto;
        padding: 10px;
        &:hover {
            @include transition;
            color: $color-green;
            background-color: #ffffff;
        }
        &:disabled {
            color: $color-grey;
            background-color: $color-light-grey;
            border-color: $color-grey;
            cursor: not-allowed;
        }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Checkbox */
    .form-item-checkbox {
        margin-bottom: 12px;
        .regular-checkbox {
            display: none;
            width: 100%;
            & + label {
                display: block;
                width: 28px;
                height: 28px;
                background-color: #fff;
                border: 2px solid #00a03b54;
                padding: 0;
                position: relative;
                float: left;
                margin: 0px 20px 0 0px;
                z-index: 2;
                border-radius: 3px;
            }
        }
        .regular-checkbox + label:active,
        .regular-checkbox:checked + label {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05),
                inset 15px 10px -12px rgba(255, 255, 255, 0.1);
        }
        .regular-checkbox:checked + label {
            &:active {
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                    inset 0 1px 3px rgba(0, 0, 0, 0.1);
            }
            &::after {
                background: url("../images/icon-check.svg") no-repeat center
                    center;
                background-size: contain;
                width: 100%;
                height: 100%;
                display: block;
                content: " ";
            }
        }
        .checkbox-text {
            line-height: 28px;
            text-align: left;
            display: block;
            a {
                color: $color-baseText;
                font-size: 14px;
                text-decoration: underline;
                line-height: 28px;
                padding-left: 48px;
                display: block;
                @media #{$mq-tab} {
                    font-size: 16px;
                }
                &:hover {
                    color: $color-link;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
