/* >> Forms
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/

/* >> LOGIN << */
.base-forms{ @extend %baseForm;
  &.base-forms-login,&.base-forms-fpw{ max-width: 400px; width: 90%; margin: 0 auto;
    .login-title{ display: block; margin: 0 auto 30px auto; font-size: 30px; font-style: italic; font-weight: normal; text-align:center;
      &:before{ content:" "; display: block; width: 60px; height: 60px; margin: 0 auto 15px auto; background: url(../images/icon-profile.svg) no-repeat center center; }
    }
    .form-item-name{ padding-left: 50px; background: url(../images/icon-profile-line.svg) no-repeat 15px center; background-size: 21px; }
    .form-item-password{ padding-left: 50px; background: url(../images/icon-password-line.svg) no-repeat 15px center; background-size: 21px; }
    .form-item-checkbox{ display: inline-block;
      .checkbox-text{width: 230px}
    }
    .btn-link{white-space: nowrap; color:$color-green;}
    .stat-text{margin-bottom: 20px;}
  }
}

/* >> REGISTRATION << */
.registration-title{ display: block; margin: 0 auto 30px auto; font-size: 30px; font-style: italic; font-weight: normal; text-align:center;
  &:before{ content:" "; display: block; width: 60px; height: 60px; margin: 0 auto 15px auto; background: url(../images/icon-profile.svg) no-repeat center center; }
}
.registration-lead{ @extend %statText; width:100%;
  a{white-space: nowrap;}
}
.base-forms{
  &.base-forms-reg{
    .registration-subtitle{display: block; font-size: 25px; padding: 20px 0;}
    .form-item{ max-width: 300px; margin: 0 auto 16px auto;
      &.form-item-w50{
        &:before{ content: " "; position: absolute; top:  64px; left: calc((100% - 40px)/2); width:40px; height: 2px; background-color: $color-green;
          @media #{$mq-slide-spec-md}{
            top: 23px; left: 47.5%;
          }
        }
        input{ width: 100%; text-align: left;
          &:first-child{position: relative; margin-bottom: 40px;}
          @media #{$mq-slide-spec-md}{ width: 200px;
            &:first-child{margin-right: 100px; margin-bottom: 0;}
          }
        }
      }
      input{text-align: center;}
    }
    .btn-submit-form{ max-width: 300px; }
  }
  &.small-form{ max-width: 400px; width: 90%; margin: 0 auto; }
}