/* Profil
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.card-content{
  &.card-content-profil{
    .profil-item{
      p,span,.form-item{ display: inline-block; width:100%;}
      .form-item{margin-bottom: 15px;}
      p,span{color: $color-white; font-size: 20px; margin-bottom: 25px; font-weight: 100;}
      p{ font-weight: bold; }
      .profil_item-editable{ display: inline-block; width: 30px; height: 30px; background: url(../images/icon-editable.svg) no-repeat center center; background-size: 30px; }
      @media #{$mq-tab_720}{  display: -ms-flexbox; display: flex; -webkit-box-flex: 0; -ms-flex: 0 1 auto; flex: 0 1 auto; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -ms-flex-wrap: wrap; flex-wrap: wrap; width: 100%;
        p{ width: 150px; }
        span{ width: 350px; }
        .form-item{ width: 350px; margin-bottom:0;}
      }
    }
    p{ color: $color-white; }
    .btn-wrapper{
      .button{margin: 32px auto 32px 0;}
    }
  }
}