/* >> Pagination
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.pagination{
  @extend %clearFix;
  width: 262px; margin: 40px auto 0; color: $color-white;
  @media #{$mq-tab_720} {
    width: 298px; border-bottom: 1px solid $color-white; padding-bottom: 16px;
  }
}
.pagination__btnWrapper{
  @extend %clearFix;
  @media #{$mq-tab_720} {
    float: left;
  }
}
.pagination__btnWrapper__btn{
  display: block; width: 34px; height: 36px; float: left; text-align: center; line-height: 36px; font-size: 14px; cursor: pointer;
  &.more{
    @include selectDisable;
    cursor: auto; width: 12px;
  }
  &.prev{
    background: url("../images/icon-prev.svg") no-repeat center center; background-size: 10px;
    &:hover{background-image: url("../images/icon-prev-h.svg");}
  }
  &.next{
    background: url("../images/icon-next.svg") no-repeat center center; background-size: 10px;
    &:hover{background-image: url("../images/icon-next-h.svg");}
  }
  .marker{
    display: inline-block; position: absolute; bottom: -16px; left: 0; width: 100%; height: 3px; background-color: $color-lime; opacity: 0; @include transition(all,linear,0.375s);
  }
  &:hover{
    color: $color-lime;
    .marker{
      @media #{$mq-tab_720} {
        opacity: 1;
      }
    }
  }
  &.current{background-color: $color-lime; color: white; border-radius: $radius;}
}
.pagination__form{
  @extend %clearFix;
  display: block; width: 72px;  margin: 4px auto;
  @media #{$mq-tab_720} {
    float: left; width: 36px; margin: 0;
  }
  input{
    display: block; width: 100%; height: 36px; border: 1px solid $color-lime; border-radius: $radius; text-align: center; outline-color: $color-green; color: $color-baseText;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button{
    @include appearencNone;
  }
}