/* >> E-Bill table
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.bill-list-table{
  background: rgba($color-white, .8); border-radius: 20px; border: 1px solid $color-green; text-align: center;
  .bill-list-table-mobile-title{ display: block; text-align:left; padding: 1em; border-bottom: 1px solid $color-green; background: $color-green; color: $color-white; border-radius: 16px 16px 0 0;
    @media #{$mq-pc_880}{ display: none; }
  }
  .bill-list-table-header{ display: none; 
    .bill-list-table-header-item{ @extend %flexBox; justify-content: center; align-items: center; font-weight: bold;
      &.account-number{ width: 15%; }
      &.performance-date{ width: 15%; }
      &.payment-deadline{ width: 15%; }
      &.net-amount{ width: 13%; }
      &.gross-amount{ width: 13%; }
      &.paid{ width: 10%; }
      &.expired{ width: 10%; }
      &.download{ width: 9%; }
      /*&.notation{ width: 5%; }*/
      .order-button-wrapper{ display: inline-block; padding-left: 5px;
        .order-up,.order-down{ display: block; width: 10px; height: 10px;}
        .order-up{ background: url(../images/icon-up.svg) no-repeat center center; background-size: 10px;}
        .order-down{ background: url(../images/icon-down.svg) no-repeat center center; background-size: 10px;  }
      }
      
    }
    @media #{$mq-pc_880}{
      display: -ms-flexbox; display: flex; -webkit-box-flex: 0; -ms-flex: 0 1 auto; flex: 0 1 auto; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -ms-flex-wrap: wrap; flex-wrap: wrap; width: 100%;
      align-items: center; padding: 1em; border-bottom: 1px solid $color-green; 
    }
  }
  .bill-list-table-body{@extend %flexBox; padding:0; font-size: 16px;
    .bill-list-table-row{ @extend %flexBox; min-height: 40px; align-items: center; padding: 1em;
      @media screen and (max-width: 719px){&:nth-child(2n){background: #3c7e792b;}}
      @media screen and (max-width: 879px) and (min-width: 720px){width: 50%; &:nth-child(4n){background: #3c7e792b;}&:nth-child(4n+1){background: #3c7e792b;}}
      @media #{$mq-pc_880}{ width: 100%; &:nth-child(2n){background: #3c7e792b;}}
      .cell{ width: 100%; color: $color-green; @extend %flexBox; margin-bottom: 10px; text-align: left;
        @media #{$mq-pc_880}{ margin-bottom: 0; text-align: center; justify-content: center;
          &.cell__data--account-number{ width: 15%; }
          &.cell__data--performance-date{ width: 15%; }
          &.cell__data--payment-deadline{ width: 15%; }
          &.cell__data--net-amount{ width: 13%; }
          &.cell__data--gross-amount{ width: 13%; }
          &.cell__data--paid{ width: 10%; }
          &.cell__data--expired{ width: 10%; }
          &.cell__data--download{ width: 9%; justify-content: space-evenly; }
          /*&.cell__data--notation{ width: 5%; }*/
        }
        .cell__data--mobiletitle{ display: inline-block; font-weight: bold; width: 140px;
          @media #{$mq-pc_880}{ display: none; }
        } 
        .cell-payment-payed{ margin: 0 auto 0 0; display: block; width: 28px; height: 28px; border: 2px solid #00a03b54; border-radius: 3px; background: $color-white url(../images/icon-check.svg) no-repeat center center; background-size: contain;
          @media #{$mq-pc_880}{ margin: 0 auto; }
        }
        .cell-payment-unpayed{ margin: 0 auto 0 0; display: block; width: 28px; height: 28px; border: 2px solid #fb344954; border-radius: 3px; background: $color-white url(../images/icon-x.svg) no-repeat center center; background-size: contain;
          @media #{$mq-pc_880}{ margin: 0 auto; }
        }
        .cell-expired{ margin: 0 auto 0 0; display: block; width: 28px; height: 28px; border: 2px solid #00a03b54; border-radius: 3px; background: $color-white url(../images/icon-check.svg) no-repeat center center; background-size: contain;
          @media #{$mq-pc_880}{ margin: 0 auto; }
        }
        .cell-not-expired{ margin: 0 auto 0 0; display: block; width: 28px; height: 28px; border: 2px solid #fb344954; border-radius: 3px; background: $color-white url(../images/icon-x.svg) no-repeat center center; background-size: contain;
          @media #{$mq-pc_880}{ margin: 0 auto; }
        }
        .base-forms .form-item-checkbox .regular-checkbox + label {
          margin: 0 auto; float: none;
        }
        .cell__data--download-btn { display: inline-block; font-size: 10px; color: white; background: green; padding: 8px 5px; border-radius: 5px; margin: 0 5px 0 0;
          @media #{$mq-pc_880}{ margin: 0 auto; }
        }
      }
    }
  }
}