/* >> Reset
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, img, ins, kbd, q, s, samp, small, strike, sub, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  vertical-align: baseline;
}
:focus { outline: 0; }
article, aside, details, figcaption, figure, footer, header, menu, nav, section{display: block;}
body{line-height: 1;}
ol, ul{list-style: none; margin: 0; padding: 0;}
blockquote, q{quotes: none;}
table{border-collapse: collapse; border-spacing: 0;}
code{font-family: monospace;}
b, strong{font-weight: bold;}
*{box-sizing: border-box; -moz-box-sizing: border-box; position: relative;}
img{width: 100%; margin: 0; padding: 0; border: none; outline: none; display: block;}
a{outline: none; text-decoration: none;}
.chromeframe { margin: 0; padding: 0; }