.flex-center {
    display: flex; align-items: center; justify-content: center;
}
.flex-column {
    flex-direction: column;
}

.status-info-success {
    margin: 0 0 15px;
    padding: 15px;
    border: 1px solid $color-border;
    background: #00a03b42;
    border-radius: $radius;
}
