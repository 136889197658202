/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- *\
Scss - Variables
\* --- --- --- --- --- --- --- --- --- --- --- --- --- --- */
$color-selection: rgba(0, 160, 59, 0.5);
$baseFont: 'Teva Sans Web';
$color-baseText:#00a03b;

/* >> Teva Colors
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
$color-green: #00a03b;
$color-lime:#afcb37;
$color-white:#ffffff;
$color-teal:#3c7e79;
$color-blue:#00567a;
$color-purple:#aa198d;
$color-red:#fb3449;
$color-light-grey: #f6f6f6;
$color-grey: #8f8d8d;
$color-link: #afcb37;

/* >> Other
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
$radius: 5px;
$color-border:#00a03b;
$color-formOutline: #afcb37;;

/* >> Breakpoints
--- --- --- --- --- --- --- --- --- --- --- --- --- --- */
$mq-mobil_spec: "only screen and (max-width: 519px)";
$mq-tab: "only screen and (min-width: 520px)";
$mq-slide-spec-sm: "only screen and (min-width: 480px)";
$mq-slide-spec-md: "only screen and (min-width: 600px)";
$mq-tab_720: "only screen and (min-width: 720px)";
$mq-iPad_port: "only screen and (min-width: 768px)";
$mq-tab_spec: "only screen and (min-width: 520px) and (max-width: 959px)";
$mq-pc_880: "only screen and (min-width: 880px)";
$mq-pc_960: "only screen and (min-width: 960px)";
$mq-iPad_land: "only screen and (min-width: 1024px)";
$mq-pc_1200: "only screen and (min-width: 1200px)";