/* >> Attachment Gallery, Video, Docs
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.attachment-gallery{
  @extend %clearFix; margin: 30px auto 0; max-width: 860px;
}
.attachment-gallery-title{display: block; width: 100%; margin-bottom: 8px; font-weight: normal; font-size: 25px; font-family: $baseFont; color: $color-green; letter-spacing: 0.02em;}
.attachment-gallery-noimg{
  width: 100%; margin-bottom: 16px; padding-bottom: 16px; border-bottom: 1px solid #f3f3f3; font-size: 14px; font-weight: 300;
  span{color: $color-teal;}
}
.thumb-gallery{
  @extend %flexBox; justify-content: flex-start;
}
.thumb-gallery-navigator{
  @extend %flexBox; display: none;
  @media #{$mq-tab_720} {
    display: flex; width: 20%; max-width: 20%; flex-basis: 20%; margin-right: 1%; align-content: space-between;
  }
}
.thumb-gallery-navigator-wrapper{
  @extend %clearFix;
}
.thumb-gallery-navigator-wrapper-item{margin: 8px 0; border: 1px solid $color-border; border-radius: 20px;}
.btn-perv-humb-img,
.btn-next-thumb-img{width: 100%; height: 36px; cursor: pointer; background-size: 36px;}
.btn-perv-thumb-img{background: url("../images/icon-up.svg") no-repeat center center; background-size: 30px;}
.btn-next-thumb-img{background: url("../images/icon-down.svg") no-repeat center center; background-size: 30px;}
.thumb-gallery-big-img{
  @extend %flexBox; align-items: center;border: 1px solid $color-border; border-radius: 20px; overflow: hidden;
  width: 100%; max-width: 100%; flex-basis: 100%;
  @media #{$mq-tab_720} {
    width: 79%; max-width: 79%; flex-basis: 79%;
  }
}
.thumb-gallery-bigimg-enlarge{
  display: block; width: 32px; height: 32px; position: absolute; right: 16px; bottom: 16px; z-index: 10; cursor: pointer;
}

.attachment-wrapper{@extend %clearFix;@extend %flexBox; margin: 30px auto 0; max-width: 860px;}
.attach-files{
  @extend %clearFix;
  width: 100%;
  &.is-video{
    width: 100%;
    @media #{$mq-pc_960} {
      width: calc(100% - 382px);
    }
  }
}
.attach-files-block-title{
  @extend %clearFix; display: block; width: 100%; font-weight: normal; border-bottom: 1px solid $color-border; padding-bottom: 16px; margin-bottom: 16px; font-size: 25px; font-family: $baseFont; color: $color-green; letter-spacing: 0.02em;
}
.attach-files-item-wrapper{
  @extend %clearFix;
  @extend %flexBox;
  justify-content: flex-start;
}
.attach-files-item-wrapper-item{
  display: block; width: 100%; margin: 0 auto 16px; color: $color-baseText; padding-left: 25px; background: url("../images/icon-file-green.svg") no-repeat left 3px; background-size: 18px; line-height: 24px;
  &:hover{color:$color-teal;}
}
.attach-video{
  @extend %clearFix; @extend %flexBox;
  width: 350px; max-width: 350px; margin: 0 auto;
  @media #{$mq-tab_720} {
    width: 49%; flex-basis: 49%; max-width: 350px; margin-top: 32px;margin-bottom: 30px;
  }
  @media #{$mq-pc_960} {
    width: 350px; flex-basis: 350px; max-width: 350px; margin-left: 32px; margin-top: 0;
  }
}
.attach-video-item{
  display: block; width: 100%; margin: 0 auto; border: 1px solid $color-border; border-radius: 20px; overflow: hidden;
  .clearFix{display: block;}
  .play{position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto; width: 50px;}
}