/* Communiques
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.communiques-list{ @extend %flexBox;
  .communique-item{width: 90%; margin: 15px auto;
    display: block; padding:1em; margin: 2em auto; border-radius: 20px; background: rgba(255,255,255,0.2); color: $color-white;
    @media #{$mq-tab_720}{
      width: 49%; margin-right: 2%;
      &:nth-child(2n){ margin-right: 0; }
    }
    @media #{$mq-pc_1200}{
      width: 23.5%; margin-right: 2%;
      &:nth-child(2n){ margin-right: 2%; }
      &:nth-child(4n){ margin-right: 0; }
    }
    &.communique-item-w100{width:100%;
      .communique-item-img{ float: left; max-width: 320px; padding: 0 2em 1em 0; }
    }
  }
  .communique-item-title{font-weight: bold; font-size: 18px; margin-bottom: 1em; padding-bottom: 0.5em; border-bottom: 2px solid $color-green;}
  .communique-item-lead{@extend %statText; font-size: 16px; font-weight: 100;}
}