/* >> Documents list
--- --- --- --- --- --- --- --- --- --- --- --- --- ---*/
.document-list{@extend %flexBox; @extend %clearFix;}
.document-list-item{width: 90%; margin: 15px auto;
  display: block; padding:1em; margin: 2em auto; border-radius: 20px; background: rgba(255,255,255,0.2); color: $color-white;
  @media #{$mq-tab_720}{
    width: 49%; margin:0 2% 2% 0;
    &:nth-child(2n){ margin: 0 0 2% 0; }
  }
}
.document-list-item-title{ color: $color-white; margin: 0 auto 10px 0; font-weight: bold; font-size: 18px;}
.document-list-item-date{ color: $color-white;margin: 0 0 0 auto; font-size: 13px; line-height: 24px; }
.document-list-item-lead{font-size: 16px; line-height: 26px;}
.document-list-item-btn{display: inline-block; padding: 0 0 0 30px; line-height: 20px; margin: 20px 30px 0 0; font-size: 14px; color: $color-white; background: url("../images/icon-file.svg") no-repeat left center; background-size: 15px;}